@charset "UTF-8";
@font-face {
  font-family: SourceSansPro;
  src: url("fonts/SourceSansPro-Bold.woff2");
  font-weight: 700;
  font-style: normal;
  font-display: auto;
}
@font-face {
  font-family: SourceSansPro;
  src: url("fonts/SourceSansPro-Regular.woff2");
  font-weight: 400;
  font-style: normal;
  font-display: auto;
}
@font-face {
  font-family: SourceSansPro;
  src: url("fonts/SourceSansPro-Light.woff2");
  font-weight: 300;
  font-style: normal;
  font-display: auto;
}
@font-face {
  font-family: SourceSansPro;
  src: url("fonts/SourceSansPro-ExtraLight.woff2");
  font-weight: 200;
  font-style: normal;
  font-display: auto;
}
@font-face {
  font-family: Flexo;
  src: url("fonts/Flexo-Heavy.woff2");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: Flexo;
  src: url("fonts/Flexo-Bold.woff2");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: Flexo;
  src: url("fonts/Flexo-Regular.woff2");
  font-weight: 400;
  font-style: normal;
}
@tailwind base;
@tailwind components;
@tailwind utilities;
:root {
  --c-primary-red: rgba(222, 53, 9, 1);
  --c-primary-orange: rgba(229, 104, 40, 1);
  --c-primary-blue: rgba(55, 75, 95, 1);
  --c-primary-white: rgba(255, 255, 255, 1);
  --c-primary-gray: rgba(73, 73, 73, 1);
  --c-additional-blue: rgba(78, 117, 142, 1);
  --c-additional-gray: rgba(156, 153, 155, 1);
  --c-additional-black: rgba(36, 36, 38, 1);
  --c-opacity-primary-red-80: rgba(222, 53, 9, 0.8);
  --c-opacity-orange-80: rgba(229, 104, 40, 0.8);
  --c-opacity-blue-80: rgba(55, 75, 95, 0.8);
  --c-opacity-additional-blue-80: rgba(78, 117, 142, 0.8);
  --c-opacity-additional-gray-80: rgba(156, 153, 155, 0.8);
  --c-opacity-primary-red-50: rgba(222, 53, 9, 0.5);
  --c-opacity-orange-50: rgba(229, 104, 40, 0.5);
  --c-opacity-blue-50: rgba(55, 75, 95, 0.5);
  --c-opacity-additional-blue-50: rgba(78, 117, 142, 0.5);
  --c-opacity-additional-gray-50: rgba(156, 153, 155, 0.5);
  --c-extra-black: rgba(32, 31, 31, 1);
  --c-extra-white: rgba(246, 246, 246, 1);
  --c-extra-gray: rgba(55, 55, 55, 1);
  --c-extra-light-brown: rgba(106, 94, 87, 1);
  --c-extra-light-grey: rgba(239, 239, 239, 1);
  --c-extra-blue: rgba(47, 76, 94, 1);
  --c-extra-orange: rgba(232, 114, 83, 1);
  --c-orange-light: rgba(222, 96, 53, 1);
  --c-orange-extralight: rgba(210, 108, 67, 1);
  --c-orange-extradark: rgb(159, 43, 17);
  --c-primary: var(--c-primary-red);
  --c-hover: var(--c-primary-red);
  --c-text: var(--c-additional-black);
  --c-text-contrast: var(--c-primary-white);
  --c-link-hover: var(--c-primary-red);
  --c-headers: var(--c-additional-black);
  --f-family-titles: "Flexo", sans-serif;
  --f-family-body: "SourceSansPro", sans-serif;
}

html {
  font-size: 62.5%;
  scroll-behavior: smooth;
}

body {
  background-color: var(--c-extra-white);
  font-family: var(--f-family-body);
  font-size: 1.8rem;
  font-weight: 400;
  line-height: 1.5;
}
body.mobile-menu-open #main-content,
body.mobile-menu-open footer {
  display: none;
}
body.mobile-menu-open header.header--main-header {
  height: 100vh;
}
body.mobile-menu-open header.header--main-header .header-image {
  position: relative;
  z-index: 15;
}
body.mobile-menu-open .header__mobile-menu {
  color: var(--c-text);
}

/* Typographi */
h1, h2, h3, h4, h5, h6, .subtitle,
.h1, .h2, .h3, .h4, .h5 {
  font-family: var(--f-family-titles);
  font-weight: 700;
  font-style: normal;
  line-height: 1.2;
  color: var(--c-headers);
}

h1, .h1 {
  font-size: 3.2rem;
}
@media (min-width: 380px) {
  h1, .h1 {
    font-size: 4.2rem;
  }
}
@media (min-width: 768px) {
  h1, .h1 {
    font-size: 5rem;
  }
}

h2, .h2 {
  font-size: 2.8rem;
}
@media (min-width: 768px) {
  h2, .h2 {
    font-size: 4.2rem;
  }
}

h3, .h3 {
  font-size: 2.4rem;
}
@media (min-width: 768px) {
  h3, .h3 {
    font-size: 3.2rem;
  }
}

h4, .h4 {
  font-size: 2rem;
}
@media (min-width: 768px) {
  h4, .h4 {
    font-size: 2.4rem;
  }
}

h5, .h5 {
  font-size: 1.8rem;
}
@media (min-width: 768px) {
  h5, .h5 {
    font-size: 1.8rem;
  }
}

h6, .h6 {
  font-size: 1.6rem;
}
@media (min-width: 768px) {
  h6, .h6 {
    font-size: 1.6rem;
  }
}

* + h2, * + h3, * + h4, * + h5, * + h6 {
  margin-bottom: 0.5rem;
}

* + h2 {
  margin-top: 1.8rem;
}

* + h3 {
  margin-top: 1.2rem;
}

* + h4 {
  margin-top: 1rem;
}

* + h5 {
  margin-top: 0.8rem;
}

.details {
  font-size: 1.4rem;
}

a {
  color: var(--c-link);
  text-decoration: underline;
  cursor: pointer;
}
a:is(:hover, :active, :focus) {
  color: var(--c-link-hover);
}
a:focus {
  outline: 2px solid var(--c-link-hover);
}

.text__body p + p {
  margin-top: 1em;
}
.text__body h2, .text__body h3, .text__body h4 {
  font-family: var(--f-family-titles);
  margin-top: 1.5em;
  margin-bottom: 0.5em;
}
.text__body hr {
  margin-top: 4rem;
  margin-bottom: 4rem;
  border: 1px solid var(--c-primary-red);
}
@media (min-width: 768px) {
  .text__body hr {
    margin-top: 6rem;
    margin-bottom: 6rem;
  }
}
.text__body ol, .text__body ul {
  margin-block-start: 1em;
  margin-block-end: 1em;
  padding-inline-start: 4rem;
  margin-top: 1em;
  margin-bottom: 1em;
}
.text__body ol ol, .text__body ol ul, .text__body ul ol, .text__body ul ul {
  margin-block-start: 0.5em;
  margin-block-end: 0.5em;
}
.text__body ol li, .text__body ul li {
  margin-bottom: 1rem;
}
.text__body ol li:last-child, .text__body ul li:last-child {
  margin-bottom: 0;
}
.text__body ol {
  list-style-type: decimal;
}
.text__body ul {
  list-style-type: disc;
}

.text__intro {
  font-weight: 300;
  font-size: 2rem;
  color: var(--c-extra-grey);
}
@media (min-width: 768px) {
  .text__intro {
    font-size: 2.2rem;
  }
}

.text__body {
  font-size: 1.8rem;
}
@media (min-width: 768px) {
  .text__body {
    font-size: 2rem;
  }
}

table {
  width: 100%;
  max-width: 100%;
  overflow-x: auto;
  display: block;
  border: 1px solid var(--c-opacity-additional-gray-50);
}
table::-webkit-scrollbar {
  -webkit-appearance: none;
  height: 6px;
}
table::-webkit-scrollbar-thumb {
  background: var(--c-extra-light-brown);
  border-radius: 5px;
  cursor: pointer;
}
table::-webkit-scrollbar-thumb:hover {
  background: var(--c-extra-light-brown);
}
table caption {
  border-bottom: 1px solid var(--c-opacity-additional-gray-50);
  padding: 1rem;
}
table td, table th {
  padding: 1rem;
  border-bottom: 1px solid var(--c-opacity-additional-gray-50);
  border-right: 1px solid var(--c-opacity-additional-gray-50);
  text-align: left;
}
table tbody tr:last-child td, table tbody tr:last-child th {
  border-bottom: 0;
}
table td:last-child, table th:last-child {
  border-right: 0;
}

.max-page {
  margin-left: auto;
  margin-right: auto;
  max-width: 136rem;
  width: 100%;
  padding: 0 1.5rem;
}
@media (min-width: 768px) {
  .max-page {
    padding: 0 4rem;
  }
}

.max-text {
  margin-left: auto;
  margin-right: auto;
  max-width: 71rem;
}

.background-white {
  background-color: var(--c-primary-white);
}

.background-orange {
  background-color: var(--c-primary-red);
}

.background-gray {
  background-color: var(--c-extra-gray);
}

.background-lightgray {
  background-color: var(--c-extra-white);
}

.background-black {
  background-color: var(--c-extra-black);
}

.background-orange-light {
  background-color: var(--c-orange-light);
}

.background-orange-extralight {
  background-color: var(--c-orange-extralight);
}

.richtext-image.left {
  float: left;
  margin: 2rem 2rem 2rem 0;
}
.richtext-image.right {
  float: right;
  margin: 2rem 0 2rem 2rem;
}
.richtext-image.full-width {
  margin: 2rem 0;
  width: 100%;
}

.to-maincontent-button {
  position: absolute;
  z-index: 1000;
  left: -10rem;
  top: -10rem;
  opacity: 0;
}
.to-maincontent-button:focus {
  opacity: 1;
  left: 3rem;
  top: 3rem;
}

@media (prefers-reduced-motion: no-preference) {
  .slide-in--hidden {
    opacity: 0;
    filter: blur(5px);
    transform: translateY(10%);
    transition-property: opacity, filter, transform;
    transition-duration: 500ms;
  }
  .slide-in--show {
    opacity: 1;
    filter: blur(0);
    transform: translateY(0);
  }
}
.component__popup-modal {
  position: fixed;
  width: 100vw;
  height: 100vh;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #eeeeee;
}
.component__popup-modal.open {
  visibility: visible;
  opacity: 1;
  transition-delay: 0s;
  backdrop-filter: blur(10px);
  z-index: 2;
}
.component__popup-modal .popup-modal__bg {
  position: absolute;
  width: 100%;
  height: 100%;
}
.component__popup-modal .popup-modal__container {
  background: #242426;
  position: relative;
  padding: 70px;
  box-shadow: 10px 13px 0px 3px rgb(229, 104, 40);
  -webkit-box-shadow: 10px 13px 0px 3px rgb(229, 104, 40);
  -moz-box-shadow: 10px 13px 0px 3px rgb(229, 104, 40);
}
.component__popup-modal .popup-modal__close {
  position: absolute;
  right: 15px;
  top: 15px;
  outline: none;
  appearance: none;
  color: #ffffff;
  background: none;
  border: 0;
  font-weight: bold;
  cursor: pointer;
}

.btn {
  display: block;
  padding: 1rem 3rem;
  font-size: 2rem;
  font-weight: 500;
  line-height: normal;
  text-decoration: none;
  border-radius: 5rem;
  border-width: 0.3rem;
  border-style: solid;
  cursor: pointer;
  transition: 0.2s;
  text-align: center;
}
.btn:is(:hover, :focus, :active) {
  outline: none;
}
.btn i {
  margin-left: 1rem;
}
@media (min-width: 768px) {
  .btn {
    font-size: 2.4rem;
  }
}

.btn-primary {
  border-color: var(--c-primary);
  background-color: var(--c-primary);
  color: var(--c-extra-white);
}
.btn-primary:is(:hover, :focus, :active) {
  color: var(--c-primary);
  border-color: var(--c-primary);
  background-color: var(--c-extra-white);
}

.btn-secondary {
  border-color: var(--c-primary);
  background-color: var(--c-extra-white);
  color: var(--c-primary);
}
.btn-secondary:is(:hover, :focus, :active) {
  border-color: var(--c-primary);
  background-color: var(--c-primary);
  color: var(--c-text-contrast);
}

.btn-tertiary {
  border-color: var(--c-extra-white);
  background-color: var(--c-primary);
  color: var(--c-extra-white);
}
.btn-tertiary:is(:hover, :focus, :active) {
  border-color: var(--c-primary);
  background-color: var(--c-extra-white);
  color: var(--c-primary);
}

.component__zoom-buttons {
  font-size: 2.4rem;
  font-weight: 500;
}
.component__zoom-buttons button {
  color: var(--c-extra-light-brown);
  border-radius: 50%;
  width: 3.6rem;
  transition: 0.2s;
}
.component__zoom-buttons button:is(:hover, :focus, :active) {
  color: var(--c-text);
  outline: 2px solid var(--c-opacity-additional-gray-50);
}

div.input-block {
  position: relative;
}
div.input-block input[type=text], div.input-block input[type=tel], div.input-block input[type=email] {
  font-weight: 400;
  font-size: 2rem;
  width: 100%;
  outline: none;
  padding: 1.5rem;
  height: 5rem;
}
div.input-block input[type=text] + label, div.input-block input[type=tel] + label, div.input-block input[type=email] + label {
  padding: 1.5rem 0;
  font-size: 1.4rem;
  top: 0.1rem;
  left: 2.4rem;
  transition: all 0.3s;
  transform-origin: 0 0;
  pointer-events: none;
  display: block;
  z-index: 5;
}

.header--main-header {
  padding: 5rem 0 3rem;
}
.header--main-header .header-image__link:is(:hover, :focus, :active) {
  opacity: 0.6;
}
.header--main-header .header-image__link:focus {
  text-decoration: underline;
  text-underline-offset: 1rem;
  text-decoration-thickness: 0.4rem;
  text-decoration-color: var(--c-primary);
}
.header--main-header .header-image__image {
  width: 6.2rem;
}
.header--main-header .header-image__image--white {
  display: none;
}

nav.main-menu {
  margin-top: 2.4rem;
}
nav.main-menu .desktop-menu__menu-item {
  position: relative;
}
nav.main-menu .desktop-menu__menu-item:after {
  content: "";
  height: 1rem;
  width: 100%;
  position: absolute;
  bottom: -1rem;
  left: 0;
}
nav.main-menu .desktop-menu__menu-item + .desktop-menu__menu-item {
  margin-left: 2.4rem;
}
nav.main-menu .desktop-menu__menu-item .sub_menu {
  display: none;
  position: absolute;
  min-width: 28rem;
  background-color: var(--c-primary-white);
  padding: 2rem 3rem;
  box-shadow: 0 0.4rem 1.5rem 0 rgba(0, 0, 0, 0.1);
  border-radius: 0rem 0rem 1.5rem 0.5rem;
  margin-left: -3rem;
  margin-top: 1rem;
  z-index: 10;
}
nav.main-menu .desktop-menu__menu-item .sub_menu:before {
  content: "";
  position: absolute;
  left: 0;
  top: 2rem;
  bottom: 2rem;
  width: 0.5rem;
  background-color: var(--c-opacity-additional-gray-50);
  border-radius: 0 0.3rem 0.3rem 0;
  max-height: 11rem;
}
nav.main-menu .desktop-menu__menu-item .sub_menu::after {
  content: "";
  position: absolute;
  top: -2rem;
  left: 4rem;
  border-width: 1rem;
  border-style: solid;
  border-color: transparent transparent var(--c-primary-white) transparent;
}
nav.main-menu .desktop-menu__menu-item:is(:hover, :focus-within) > .sub_menu {
  display: block;
}
nav.main-menu .menu-item__link {
  text-decoration: none;
  color: var(--c-text);
  font-weight: 600;
  font-size: 2.2rem;
  font-family: var(--f-family-titles);
  position: relative;
  transition: all 0.3s;
}
nav.main-menu .menu-item__link:is(:hover, :focus, :active):after {
  height: 0.4rem;
  width: 3.2rem;
  content: "";
  bottom: -7px;
  left: 0;
  background-color: var(--c-primary-blue);
  display: block;
  border-radius: 2px;
  position: absolute;
}
nav.main-menu .menu-item__link.active:after {
  position: absolute;
  height: 0.4rem;
  width: 3.2rem;
  content: "";
  bottom: -7px;
  left: 0;
  background-color: var(--c-hover);
  display: block;
  border-radius: 2px;
}
nav.main-menu .mobile-menu__menu {
  position: absolute;
  left: 8rem;
  top: 19rem;
}
nav.main-menu .mobile-menu__menu-button:is(:hover, :focus, :active) {
  opacity: 0.6;
}
nav.main-menu .mobile-menu__menu-button:focus {
  text-decoration: underline;
  text-underline-offset: 1rem;
  text-decoration-thickness: 0.4rem;
  text-decoration-color: var(--c-primary);
}
nav.main-menu .mobile-menu__menu-button__content--close {
  display: none;
}
nav.main-menu .mobile-menu__menu-button__content--menu {
  display: block;
}
nav.main-menu .mobile-menu__menu-item {
  margin-bottom: 3rem;
  position: relative;
}
nav.main-menu .mobile-menu__menu-item .menu-item__link, nav.main-menu .mobile-menu__menu-item .sub_menu-item__link {
  color: var(--c-text-contrast);
}
nav.main-menu .mobile-menu__menu-item .menu-item__link:is(:hover, :focus, :active):after, nav.main-menu .mobile-menu__menu-item .sub_menu-item__link:is(:hover, :focus, :active):after {
  position: absolute;
  background-color: var(--c-extra-white);
  bottom: -7px;
}
nav.main-menu .mobile-menu__menu-item .menu-item__link.active:after, nav.main-menu .mobile-menu__menu-item .sub_menu-item__link.active:after {
  position: absolute;
  background-color: var(--c-extra-blue);
  bottom: -7px;
}
nav.main-menu .mobile-menu__menu-item .sub_menu .sub_menu-item {
  margin-left: 2rem;
}

.mobile-menu-open {
  background-color: var(--c-additional-blue);
}
.mobile-menu-open .header-image__image {
  display: none;
}
.mobile-menu-open .header-image__image--white {
  display: block;
}
.mobile-menu-open.main-menu .mobile-menu__menu-button__content--close {
  display: block;
}
.mobile-menu-open.main-menu .mobile-menu__menu-button__content--menu {
  display: none;
}

.sub_menu .sub_menu-item {
  padding: 1rem 0rem;
  font-size: 2rem;
}
.sub_menu .sub_menu-item__link {
  text-decoration: none;
  color: var(--c-extra-gray);
  font-weight: 600;
  font-size: 2rem;
  font-family: var(--f-family-titles);
  position: relative;
  display: block;
}
.sub_menu .sub_menu-item__link:is(:hover, :focus, :active) {
  color: var(--c-link-hover);
  padding-left: 2.4rem;
}
.sub_menu .sub_menu-item__link:is(:hover, :focus, :active):after {
  height: 0.4rem;
  width: 1.4rem;
  content: "";
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  background-color: var(--c-primary-red);
  display: block;
  border-radius: 2px;
  position: absolute;
}

footer {
  padding: 0rem 0 3rem 0;
  position: relative;
  margin-top: 6rem;
}

.footer__content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: var(--c-extra-grey);
}
.footer__content p + p {
  margin-top: 3rem;
}
@media (min-width: 768px) {
  .footer__content {
    flex-direction: row;
  }
}
.footer__content h2 {
  font-family: var(--f-family-titles);
  color: var(--c-extra-grey);
  font-size: 3rem;
  font-weight: bold;
}
@media (min-width: 768px) {
  .footer__content h2 {
    margin: 2rem 0 1rem 0;
  }
}
.footer__content h3 {
  font-family: var(--f-family-titles);
  color: var(--c-extra-grey);
  font-size: 2.4rem;
  font-weight: bold;
}
@media (min-width: 768px) {
  .footer__content h3 {
    margin: 2rem 0 1rem 0;
  }
}
.footer__content__socials {
  display: flex;
  gap: 2rem;
  margin-bottom: 2rem;
}
@media (min-width: 768px) {
  .footer__content__socials {
    flex-direction: column;
  }
}
.footer__content__socials__social-link {
  text-decoration: none;
  transition: color 0.3s;
}
.footer__content__socials__social-link:hover {
  color: var(--c-link-hover);
}
.footer__content__socials__social-label {
  display: inline-block;
  font-family: var(--f-family-titles);
  color: var(--c-extra-grey);
  margin-right: 1rem;
}
.footer__content__header {
  display: flex;
  gap: 2rem;
  flex-direction: column;
}
.footer__content__header__logo {
  display: flex;
  gap: 1.5rem;
}
.footer__content__header__wrapper {
  display: flex;
  flex-direction: column;
}
.footer__content__header__title {
  color: var(--c-extra-grey);
}
.footer__content__header__ingress {
  color: var(--c-extra-grey);
  font-size: 1.4rem;
  font-weight: 250;
  margin: 0;
}
.footer__content__header__image {
  width: 5.8rem;
  height: 5.6rem;
}
.footer__content__body {
  margin-bottom: 3rem;
}
.footer__content__watermark {
  font-weight: 300;
  font-family: var(--f-family-body);
  padding-bottom: 2rem;
}

.breadcrumbs__breadcrumb {
  font-size: 1.4rem;
  text-decoration: none;
  color: var(--c-extra-light-brown);
}
.breadcrumbs__breadcrumb--link {
  font-weight: 600;
}
.breadcrumbs__breadcrumb--link:after {
  content: "/";
}

.page--home-page .home-page__top_wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}
@media (min-width: 768px) {
  .page--home-page .home-page__top_wrapper {
    flex-direction: row;
  }
}
.page--home-page .home-page__top {
  padding-top: 3rem;
  padding-bottom: 3rem;
}
@media (min-width: 768px) {
  .page--home-page .home-page__top {
    min-height: 50rem;
    padding-top: 5rem;
    padding-bottom: 5rem;
  }
}
.page--home-page .home-page__top__textfield {
  padding-right: 4rem;
}
.page--home-page .home-page__top__title {
  font-weight: 400;
  line-height: 1.5;
}
.page--home-page .home-page__top__title h1 {
  font-size: 4rem;
  font-weight: 400;
}
.page--home-page .home-page__top__title h1 b {
  color: var(--c-primary);
  font-weight: 700;
}
@media (min-width: 768px) {
  .page--home-page .home-page__top__title h1 {
    font-size: 6rem;
  }
}
.page--home-page .home-page__top__ingress {
  font-family: var(--f-family-body);
  font-weight: 300;
  max-width: 50rem;
}
.page--home-page .home-page__top__title, .page--home-page .home-page__top__ingress {
  margin-bottom: 1rem;
}
@media (min-width: 768px) {
  .page--home-page .home-page__top__title, .page--home-page .home-page__top__ingress {
    margin-bottom: 4rem;
  }
}
.page--home-page .home-page__top__img {
  width: 100%;
  margin: 5rem 0 5rem 0;
}
.page--home-page .home-page__top__button {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  align-items: center;
  margin-top: 3rem;
}
@media (min-width: 768px) {
  .page--home-page .home-page__top__button {
    flex-direction: row;
    padding: 0;
    gap: 1rem;
  }
}
@media (min-width: 1200px) {
  .page--home-page .home-page__top__button {
    flex-direction: row;
    gap: 5rem;
    padding: 0;
  }
}
.page--home-page .home-page__top__button__primary {
  width: 21rem;
  text-align: center;
}
@media (min-width: 768px) {
  .page--home-page .home-page__top__button__primary {
    width: 24rem;
  }
}

.page--page .page__top {
  margin-top: 9rem;
}
@media (min-width: 768px) {
  .page--page .page__top {
    min-height: 50rem;
  }
}
.page--page .page__top__title {
  margin-bottom: 3rem;
}
@media (min-width: 768px) {
  .page--page .page__top__title {
    margin-bottom: 4rem;
  }
}
.page--page .page__top__subtitle {
  color: var(--c-primary);
  margin-bottom: 3rem;
}
.page--page .page__top__ingress {
  max-width: 50rem;
}
.page--page .page__top__img {
  margin: 5rem 0 5rem 0;
}
@media (min-width: 768px) {
  .page--page .page__top__img {
    gap: 10rem;
    margin: 0;
  }
}
.page--page .page__top_wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}
@media (min-width: 768px) {
  .page--page .page__top_wrapper {
    flex-direction: row;
    gap: 8rem;
  }
}

.page--article .article__banner {
  background-repeat: no-repeat;
  background-position: bottom right;
  padding-top: 3rem;
  padding-bottom: 2rem;
  background-size: 0;
}
@media (min-width: 768px) {
  .page--article .article__banner {
    padding-top: 18rem;
    padding-bottom: 6rem;
    background-size: contain;
  }
}
@media (min-width: 768px) {
  .page--article .article__content {
    margin-top: -3rem;
    margin-left: -5rem;
    margin-right: -5rem;
    width: calc(100% + 10rem);
    padding: 5rem;
    background: linear-gradient(180deg, #F0F0F0 0%, var(--c-primary-white) 47.92%);
    border-radius: 5rem 5rem 0 0;
  }
}
.page--article .article__top {
  margin-bottom: 5rem;
}
.page--article .article__top__back-link {
  text-decoration: none;
  color: var(--c-extra-light-brown);
  transition: 0.2s;
  font-family: var(--f-family-titles);
}
.page--article .article__top__back-link__arrow {
  width: 1.8em;
  height: 1.8em;
  border: 2px solid var(--c-opacity-additional-gray-50);
  border-radius: 50%;
  padding: 0.3em;
  text-align: center;
  transition: 0.2s;
  margin-right: 1.5rem;
}
.page--article .article__top__back-link:is(:hover, :focus, :active) {
  color: var(--c-text);
}
.page--article .article__top__back-link:is(:hover, :focus, :active) .article__top__back-link__arrow {
  border-color: var(--c-text);
}
.page--article .article__author__image img {
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  width: 8rem;
  height: 8rem;
}
.page--article .article__author__name {
  font-size: 2rem;
  font-weight: 400;
  color: var(--c-extra-light-brown);
}
.page--article .article__author__job-title {
  font-size: 1.4rem;
  color: var(--c-extra-light-brown);
  font-family: var(--f-family-titles);
}
.page--article .article__hr {
  background-color: var(--c-extra-white);
  height: 0.2rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
}
.page--article .article__tags__tag {
  padding: 0.7rem 2rem;
  background-color: #e4e4e4;
  line-height: 1;
  border-radius: 0.4rem;
  text-decoration: none;
  font-family: var(--f-family-titles);
  font-size: 1.4rem;
  transition: 0.2s;
}
.page--article .article__tags__tag:is(:hover, :focus, :active) {
  background-color: var(--c-opacity-additional-gray-50);
  color: var(--c-text);
}

.page--portfolio-page {
  margin-top: 9rem;
}
.page--portfolio-page .portfolio__content {
  background-image: url("/static/website/images/rectangle_12.svg");
  background-position: center 20rem;
  background-repeat: no-repeat;
}
.page--portfolio-page .portfolio__customer {
  text-transform: uppercase;
  font-size: 3rem;
  font-family: var(--f-family-titles);
  margin-bottom: 3rem;
}
@media (min-width: 768px) {
  .page--portfolio-page .portfolio__customer {
    margin-bottom: 6rem;
  }
}
.page--portfolio-page .portfolio__title h1 {
  font-size: 2.4rem;
  font-family: var(--f-family-body);
  margin-bottom: 3rem;
}
@media (min-width: 768px) {
  .page--portfolio-page .portfolio__title h1 {
    font-size: 2.8rem;
    margin-bottom: 6rem;
  }
}
@media (min-width: 976px) {
  .page--portfolio-page .portfolio__title h1 {
    font-size: 3.8rem;
  }
}
.page--portfolio-page .portfolio__buttons {
  margin-bottom: 3rem;
  display: flex;
  column-gap: 2rem;
}
@media (min-width: 768px) {
  .page--portfolio-page .portfolio__buttons {
    margin-bottom: 8rem;
  }
}
.page--portfolio-page .portfolio__intro {
  margin-bottom: 3rem;
}
@media (min-width: 768px) {
  .page--portfolio-page .portfolio__intro {
    margin-bottom: 6rem;
  }
}
.page--portfolio-page .portfolio__details {
  background-color: var(--c-primary);
  padding: 1.5rem;
  margin-top: 3rem;
  color: var(--c-text-contrast);
  border-radius: 0 0 2rem 2rem;
}
@media (min-width: 480px) {
  .page--portfolio-page .portfolio__details {
    padding: 2rem;
  }
}
@media (min-width: 768px) {
  .page--portfolio-page .portfolio__details {
    padding: 3rem;
  }
}
.page--portfolio-page .portfolio__details__logo {
  margin-bottom: 3rem;
  margin-top: 3rem;
}
@media (min-width: 976px) {
  .page--portfolio-page .portfolio__details__logo {
    margin-bottom: 6rem;
    margin-top: 0rem;
  }
}
.page--portfolio-page .portfolio__details__logo img {
  width: 100%;
}
.page--portfolio-page .portfolio__details__text {
  font-size: 2.2rem;
  margin-bottom: 3rem;
}
@media (min-width: 768px) {
  .page--portfolio-page .portfolio__details__text {
    margin-bottom: 5rem;
  }
}
.page--portfolio-page .portfolio__details__tech__image {
  width: 42px;
  height: 42px;
  background-color: var(--c-extra-orange);
  padding: 9px;
  border-radius: 0.5rem;
}
.page--portfolio-page .portfolio__image {
  width: 100%;
}

.block__grid {
  background-repeat: no-repeat;
  background-position: bottom right;
  background-size: contain;
  padding-top: 3rem;
  padding-bottom: 3rem;
}
@media (min-width: 768px) {
  .block__grid {
    padding-top: 5rem;
    padding-bottom: 8rem;
  }
}
.block__grid .grid__link {
  color: var(--c-primary);
  font-family: var(--f-family-titles);
  align-items: center;
  display: flex;
  text-decoration: none;
  transition: 0.2s;
  font-size: 1.8rem;
  line-height: 1;
  gap: 1.8rem;
}
@media (min-width: 768px) {
  .block__grid .grid__link {
    font-size: 2.2rem;
  }
}
.block__grid .grid__link:is(:hover, :focus, :active) {
  color: var(--c-text);
}
.block__grid .grid__link i {
  margin-bottom: 0.3rem;
}
.block__grid .grid__link__arrow {
  font-size: 2.8rem;
}
@media (min-width: 768px) {
  .block__grid .grid__link__arrow {
    font-size: 3.4rem;
  }
}

.block__sub-items {
  margin-top: 5rem;
  position: relative;
}
@media (min-width: 976px) {
  .block__sub-items {
    margin-top: -7rem;
  }
}

.block__contactform {
  padding: 5rem 0;
}
@media (min-width: 768px) {
  .block__contactform {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
}
.block__contactform.background-orange input[type=text],
.block__contactform.background-orange input[type=tel],
.block__contactform.background-orange input[type=email] {
  background: rgba(255, 255, 255, 0.1);
  color: var(--c-extra-white);
  border-bottom: 3px solid #B82B0C;
}
.block__contactform.background-orange input[type=text]::placeholder,
.block__contactform.background-orange input[type=tel]::placeholder,
.block__contactform.background-orange input[type=email]::placeholder {
  color: var(--c-extra-white);
}
.block__contactform.background-orange .btn {
  border-color: var(--c-extra-white);
  background-color: var(--c-primary);
  color: var(--c-extra-white);
}
.block__contactform.background-orange .btn:is(:hover, :focus, :active) {
  border-color: var(--c-primary);
  background-color: var(--c-extra-white);
  color: var(--c-primary);
}
.block__contactform.background-orange .block__contactform__form-container {
  border-color: var(--c-primary);
  color: var(--c-extra-black);
}
.block__contactform.background-gray input[type=text],
.block__contactform.background-gray input[type=tel],
.block__contactform.background-gray input[type=email] {
  background: rgb(71, 71, 71);
  color: var(--c-extra-white);
  border-bottom: 3px solid #2F2D2D;
}
.block__contactform.background-gray input[type=text]::placeholder,
.block__contactform.background-gray input[type=tel]::placeholder,
.block__contactform.background-gray input[type=email]::placeholder {
  color: var(--c-extra-white);
}
.block__contactform.background-gray .btn {
  border-color: var(--c-extra-white);
  background-color: var(--c-extra-gray);
  color: var(--c-extra-white);
}
.block__contactform.background-gray .btn:is(:hover, :focus, :active) {
  border-color: var(--c-extra-gray);
  background-color: var(--c-extra-white);
  color: var(--c-extra-gray);
}
.block__contactform.background-gray .block__contactform__form-container {
  border-color: var(--c-extra-white);
  color: var(--c-extra-gray);
}
.block__contactform.background-white input[type=text],
.block__contactform.background-white input[type=tel],
.block__contactform.background-white input[type=email] {
  background: var(--c-primary-white);
  color: var(--c-text);
  border-bottom: 3px solid #EEE;
}
.block__contactform.background-white .btn {
  border-color: var(--c-primary);
  background-color: var(--c-primary-white);
  color: var(--c-primary);
}
.block__contactform.background-white .btn:is(:hover, :focus, :active) {
  border-color: var(--c-primary-white);
  background-color: var(--c-primary);
  color: var(--c-primary-white);
}
.block__contactform.background-white .block__contactform__form-container {
  border-color: var(--c-extra-black);
  color: var(--c-extra-black);
}
.block__contactform.background-black .btn {
  border-color: var(--c-extra-white);
  background-color: var(--c-text);
  color: var(--c-extra-white);
}
.block__contactform.background-black .btn:is(:hover, :focus, :active) {
  border-color: var(--c-extra-black);
  background-color: var(--c-extra-white);
  color: var(--c-extra-black);
}
.block__contactform.background-black .block__contactform__form-container {
  border-color: var(--c-extra-white);
  color: var(--c-extra-black);
}
.block__contactform.background-gray, .block__contactform.background-black, .block__contactform.background-orange {
  color: var(--c-primary-white);
}
.block__contactform.background-gray h1, .block__contactform.background-gray h2, .block__contactform.background-gray h3, .block__contactform.background-gray h4, .block__contactform.background-gray h5, .block__contactform.background-gray label, .block__contactform.background-gray a, .block__contactform.background-black h1, .block__contactform.background-black h2, .block__contactform.background-black h3, .block__contactform.background-black h4, .block__contactform.background-black h5, .block__contactform.background-black label, .block__contactform.background-black a, .block__contactform.background-orange h1, .block__contactform.background-orange h2, .block__contactform.background-orange h3, .block__contactform.background-orange h4, .block__contactform.background-orange h5, .block__contactform.background-orange label, .block__contactform.background-orange a {
  color: var(--c-primary-white);
}
.block__contactform.background-gray a:is(:hover, :focus, :active), .block__contactform.background-black a:is(:hover, :focus, :active), .block__contactform.background-orange a:is(:hover, :focus, :active) {
  color: rgba(255, 255, 255, 0.6);
}
.block__contactform__wrapper p {
  margin: 3rem 0;
}
.block__contactform__contact-person {
  display: flex;
  font-family: var(--f-family-titles);
  gap: 3rem;
  margin-top: 3rem;
  flex-direction: column;
}
@media (min-width: 380px) {
  .block__contactform__contact-person {
    flex-direction: row;
  }
}
.block__contactform__contact-person__image img {
  border-radius: 50%;
  width: 8rem;
  height: 8rem;
  border: 5px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 13px 10px 0 rgba(0, 0, 0, 0.1);
}
@media (min-width: 768px) {
  .block__contactform__contact-person__image img {
    width: 13rem;
    height: 13rem;
  }
}
.block__contactform__contact-person__wrapper {
  flex-direction: column;
  gap: 0.8rem;
}
.block__contactform__contact-person__title {
  margin-bottom: 1rem;
}
.block__contactform__contact-person__title h3 {
  font-weight: 400;
  font-size: 2.2rem;
}
@media (min-width: 768px) {
  .block__contactform__contact-person__title h3 {
    font-size: 2.8rem;
  }
}
.block__contactform__contact-person__jobtitle {
  font-size: 1.6rem;
  font-weight: 250;
  margin: 0;
}
.block__contactform__contact-person__info {
  display: flex;
  flex-direction: column;
  font-style: normal;
  white-space: nowrap;
}
.block__contactform__contact-person__info a {
  text-decoration: none;
  transition: color 0.2s;
  font-size: 1.6rem;
}
@media (min-width: 768px) {
  .block__contactform__contact-person__info a {
    font-size: 2rem;
  }
}
.block__contactform__contact-person__info__info:hover {
  color: var(--c-link-hover);
}
.block__contactform__form-container {
  width: 100%;
}
.block__contactform .input-block {
  margin-top: 1.6rem;
  margin-bottom: 3rem;
}

.block__employee-grid {
  background-color: var(--c-primary-white);
  padding-top: 9rem;
}
.block__employee-grid .employee-grid__title {
  margin-bottom: 8rem;
}
.block__employee-grid .employee-grid__employee {
  display: flex;
  flex-direction: column;
}
.block__employee-grid .employee-grid__employee__image img {
  width: 100%;
  filter: grayscale(1);
}
.block__employee-grid .employee-grid__employee__textfield {
  padding: 2rem;
  background-color: var(--c-extra-white);
  border-radius: 0 0 2rem 0.5rem;
  height: 100%;
  position: relative;
}
.block__employee-grid .employee-grid__employee__textfield:before {
  content: "";
  position: absolute;
  left: 0;
  top: 2rem;
  bottom: 2rem;
  width: 0.5rem;
  background-color: var(--c-opacity-additional-gray-50);
  border-radius: 0 0.3rem 0.3rem 0;
}
.block__employee-grid .employee-grid__employee__title {
  font-size: 2.2rem;
  font-weight: 500;
  color: var(--c-extra-gray);
}
.block__employee-grid .employee-grid__employee__location[class*=location__] {
  @apply py-1 px-2;
  margin-bottom: 2rem;
  color: var(--c-primary-white);
  font-weight: bold;
  border-radius: 9999px;
  background-color: var(--c-primary-blue);
  font-size: 1.2rem;
}
.block__employee-grid .employee-grid__employee__location.location__bergen {
  background-color: var(--c-primary-red);
}
.block__employee-grid .employee-grid__employee__location.location__skien {
  background-color: var(--c-primary-orange);
}
.block__employee-grid .employee-grid__employee__job-title {
  font-size: 1.6rem;
  font-weight: 300;
  color: var(--c-extra-gray);
  display: block;
}
.block__employee-grid .employee-grid__employee__contact-link {
  display: block;
  font-style: normal;
  font-size: 1.8rem;
  text-decoration: none;
}

.blockitem__sub-item {
  background-color: var(--c-primary-white);
  display: flex;
  flex-direction: column;
  position: relative;
  border: 4px solid var(--c-primary-white);
}
.blockitem__sub-item:hover, .blockitem__sub-item:focus-within {
  border-color: var(--c-primary);
}
.blockitem__sub-item:hover .sub-item__link::after, .blockitem__sub-item:focus-within .sub-item__link::after {
  background-color: var(--c-primary);
}
.blockitem__sub-item .sub-item__image img {
  width: 100%;
}
.blockitem__sub-item .sub-item__text-field {
  padding: 3rem;
  height: 100%;
}
.blockitem__sub-item .sub-item__text-wrapper a {
  text-decoration: none;
}
.blockitem__sub-item .sub-item__text-wrapper a::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  content: "";
}
.blockitem__sub-item .sub-item__text-wrapper a:is(:hover, :focus, :active) .sub-item__title {
  color: var(--c-hover);
}
.blockitem__sub-item .sub-item__title {
  margin-bottom: 2rem;
}
.blockitem__sub-item .sub-item__intro {
  margin-bottom: 2rem;
  font-size: 1.6rem;
}
@media (min-width: 768px) {
  .blockitem__sub-item .sub-item__intro {
    font-size: 2rem;
  }
}
.blockitem__sub-item .sub-item__link {
  text-decoration: none;
  color: var(--c-text);
  font-size: 1.6rem;
  font-family: var(--f-family-titles);
}
@media (min-width: 768px) {
  .blockitem__sub-item .sub-item__link {
    font-size: 2.3rem;
  }
}
.blockitem__sub-item .sub-item__link:after {
  height: 0.4rem;
  width: 3.2rem;
  content: "";
  bottom: -3px;
  left: 0;
  background-color: var(--c-opacity-additional-gray-50);
  display: block;
  border-radius: 2px;
}
.blockitem__sub-item .sub-item__link:is(:hover, :focus, :active):after {
  background-color: var(--c-primary);
}

.blockitem__image--graphic-decoration img {
  border-radius: 0 0 4rem 1.5rem;
  box-shadow: -2rem -2rem 0rem #F3F3F3;
}
.blockitem__image .image__link {
  text-decoration: none;
}
.blockitem__image .image__link:is(:hover, :focus, :active) {
  color: var(--c-primary-red);
  text-decoration: underline;
}
.blockitem__image .image__link:is(:hover, :focus, :active) h3 {
  color: var(--c-primary-red);
}
.blockitem__image .image__link:is(:hover, :focus, :active) .image__image {
  transition: 0.2s;
  opacity: 0.7;
}
.blockitem__image .image__title {
  margin-top: 2rem;
  display: block;
  font-weight: 500;
  font-size: 2rem;
}
.blockitem__image .image__title i {
  margin-left: 1rem;
}

.blockitem__textblock .textblock__textarea {
  font-weight: 300;
}
.blockitem__textblock .textblock__title + .textblock__textarea {
  margin-top: 1.5rem;
}
@media (min-width: 768px) {
  .blockitem__textblock .textblock__title + .textblock__textarea {
    margin-top: 2.7rem;
  }
}

.blockitem__colorblock {
  height: 100%;
  color: var(--c-text-contrast);
  padding: 3.4rem;
}
.blockitem__colorblock__title, .blockitem__colorblock h2 {
  color: var(--c-text-contrast);
  font-size: 2.6rem;
}
.blockitem__colorblock .colorblock__title + .colorblock__textarea {
  margin-top: 3rem;
}
@media (min-width: 768px) {
  .blockitem__colorblock .colorblock__title + .colorblock__textarea {
    margin-top: 2.7rem;
  }
}

.blockitem__quote {
  position: relative;
}
.blockitem__quote blockquote {
  position: relative;
  height: auto;
  color: var(--c-extra-gray);
}
@media (min-width: 768px) {
  .blockitem__quote blockquote {
    margin: 3rem 0 13rem 0;
  }
}
.blockitem__quote .quote__text {
  text-align: center;
  margin-bottom: 1rem;
}
@media (min-width: 768px) {
  .blockitem__quote .quote__text {
    font-size: 3.2rem;
  }
}
.blockitem__quote .quote__author {
  font-size: 1.2rem;
  text-align: center;
  color: #B0B0B0;
  font-weight: 300;
}
@media (min-width: 768px) {
  .blockitem__quote .quote__author {
    font-size: 2rem;
  }
}
.blockitem__quote .quote__author:before {
  content: "- ";
}
.blockitem__quote .quote__button {
  margin: 10rem auto 10rem auto;
  text-align: center;
}
@media (min-width: 768px) {
  .blockitem__quote .quote__button {
    width: 45rem;
    padding: 2rem;
    font-size: 2.8rem;
  }
}

blockquote:before {
  content: "“";
  font-size: 8rem;
  top: 0;
  left: 0;
  color: #B0B0B0;
}
@media (min-width: 768px) {
  blockquote:before {
    font-size: 15rem;
  }
}

blockquote:after {
  content: "”";
  font-size: 8rem;
  position: absolute;
  right: 0;
  color: #B0B0B0;
}
@media (min-width: 768px) {
  blockquote:after {
    font-size: 15rem;
  }
}

blockquote cite {
  font-size: 1.2rem;
  font-weight: 300;
  color: var(--c-extra-gray);
}

.blockitem__card {
  height: 100%;
  border-bottom-right-radius: 2rem;
  border-bottom-left-radius: 0.5rem;
  position: relative;
}
.blockitem__card.background-orange {
  color: var(--c-text-contrast);
}
.blockitem__card.background-orange h2 {
  color: var(--c-text-contrast);
}
.blockitem__card.background-orange .card__icon {
  background-color: var(--c-orange-extradark);
}
.blockitem__card.background-white {
  color: var(--c-extra-gray);
}
.blockitem__card.blockitem__card .card__content::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 0.4rem;
  height: 40%;
  background-color: var(--c-opacity-additional-gray-50);
  margin-top: 6rem;
  border-bottom-right-radius: 2rem;
  border-top-right-radius: 2rem;
}
.blockitem__card .card__icon {
  width: 80px;
  height: 80px;
  border-bottom-right-radius: 20px;
  background-color: var(--c-primary);
  margin-left: 2rem;
  margin-top: -5rem;
  position: absolute;
  text-align: -webkit-center;
}
.blockitem__card .card__icon .image {
  margin-top: 2.5rem;
}
.blockitem__card .card__content {
  padding: 3rem;
  position: relative;
}
.blockitem__card .content__title, .blockitem__card h4 {
  margin-top: 1.5rem;
  margin-bottom: 0.8rem;
}

.page--employeepage .page__top {
  margin-top: 9rem;
}
@media (min-width: 768px) {
  .page--employeepage .page__top {
    min-height: 50rem;
  }
}
.page--employeepage .page__top__title {
  margin-bottom: 3rem;
}
@media (min-width: 768px) {
  .page--employeepage .page__top__title {
    margin-bottom: 4rem;
  }
}
.page--employeepage .page__top__subtitle {
  color: var(--c-primary);
  margin-bottom: 3rem;
}
.page--employeepage .page__top__ingress {
  max-width: 50rem;
}
.page--employeepage .page__top__img {
  margin: 10rem 0 5rem 0;
}
@media (min-width: 768px) {
  .page--employeepage .page__top__img {
    gap: 10rem;
    margin: 5rem 0 0 0;
  }
}
.page--employeepage .page__top_wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.page--employeepage .page__top_wrapper .page__top__textfield {
  flex-grow: 1;
  width: 100%;
}
.page--employeepage .page__top_wrapper .page__top__textfield .job-title {
  font-size: 2.8rem;
  font-weight: 100;
  color: var(--c-text);
  margin-bottom: 3rem;
}
.page--employeepage .page__top_wrapper .page__top__textfield .text-content {
  color: var(--c-primary-gray);
  font-size: 2rem;
}
.page--employeepage .page__top_wrapper .page__top__textfield .text-content p {
  margin-bottom: 1.5rem;
}
.page--employeepage .page__top_wrapper .page__top__textfield .text-content p:last-child {
  margin-bottom: 0;
}
.page--employeepage .page__top_wrapper .page__top__textfield .citation {
  font-style: italic;
  font-weight: 100;
  font-size: 4.2rem;
  margin: 3rem 0 1.5rem;
}
.page--employeepage .page__top_wrapper .page__top__textfield .citation .double_quotes {
  color: var(--c-primary-red);
}
.page--employeepage .page__top_wrapper .page__top__img {
  flex-grow: 1;
  width: 85%;
  background: var(--c-extra-light-grey);
  padding-left: 3rem;
}
@media (min-width: 768px) {
  .page--employeepage .page__top_wrapper .page__top__img {
    padding-left: 2rem;
    width: 100%;
    align-self: flex-start;
  }
}
.page--employeepage .page__top_wrapper .page__top__img .image img {
  position: relative;
  margin: -5rem 0 0 -7rem;
  width: 100%;
  border-radius: 0 0 5rem 3rem;
}
.page--employeepage .page__top_wrapper .page__top__img .page__top__contact__wrapper {
  display: flex;
  gap: 2rem;
  padding: 3rem 1rem 3rem 0;
  flex-wrap: wrap;
}
@media (min-width: 768px) {
  .page--employeepage .page__top_wrapper .page__top__img .page__top__contact__wrapper {
    gap: 1.5rem;
    padding: 3rem;
  }
}
.page--employeepage .page__top_wrapper .page__top__img .page__top__contact__wrapper .email a, .page--employeepage .page__top_wrapper .page__top__img .page__top__contact__wrapper .phone a, .page--employeepage .page__top_wrapper .page__top__img .page__top__contact__wrapper .linkedin a {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  line-height: normal;
  text-decoration: none;
}
.page--employeepage .page__top_wrapper .page__top__img .page__top__contact__wrapper .email a i, .page--employeepage .page__top_wrapper .page__top__img .page__top__contact__wrapper .phone a i, .page--employeepage .page__top_wrapper .page__top__img .page__top__contact__wrapper .linkedin a i {
  display: flex;
  width: 24px;
  height: 24px;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  background: var(--c-primary-gray);
  color: var(--c-extra-light-grey);
  font-size: 1.6rem;
}
.page--employeepage .page__top_wrapper .page__top__img .page__top__contact__wrapper .email a:hover i, .page--employeepage .page__top_wrapper .page__top__img .page__top__contact__wrapper .email a:focus i, .page--employeepage .page__top_wrapper .page__top__img .page__top__contact__wrapper .phone a:hover i, .page--employeepage .page__top_wrapper .page__top__img .page__top__contact__wrapper .phone a:focus i, .page--employeepage .page__top_wrapper .page__top__img .page__top__contact__wrapper .linkedin a:hover i, .page--employeepage .page__top_wrapper .page__top__img .page__top__contact__wrapper .linkedin a:focus i {
  background: var(--c-primary-red);
}
@media (min-width: 768px) {
  .page--employeepage .page__top_wrapper {
    flex-direction: row;
    gap: 8rem;
  }
}
.page--employeepage__projects {
  padding: 10rem 0;
  margin-top: 3rem;
}
.page--employeepage__projects .projects__title {
  margin-bottom: 3rem;
}
.page--employeepage__projects .blockitem__sub-item {
  border-color: var(--c-extra-white);
  background: var(--c-extra-white);
}
.page--employeepage__projects .blockitem__sub-item:hover {
  border-color: var(--c-primary-red);
}
.page--employeepage__projects .blockitem__sub-item .sub-item__text-field {
  padding: 3rem;
  position: relative;
  display: flex;
  flex-direction: column;
}
.page--employeepage__projects .blockitem__sub-item .sub-item__text-field .sub-item__intro {
  flex-grow: 1;
  font-size: 2rem;
}
.page--employeepage__projects .blockitem__sub-item .sub-item__text-field a {
  text-decoration: none;
}
.page--employeepage__projects .blockitem__sub-item .sub-item__text-field::after {
  content: "";
  position: absolute;
  left: 0;
  top: 30px;
  width: 0.4rem;
  height: 12rem;
  border-radius: 0 3px 3px 0;
  background: var(--c-opacity-additional-gray-50);
}
.page--employeepage__projects .blockitem__sub-item .sub-item__link {
  font-size: 2.2rem;
}